@use "sass:math";

.password-policy-tooltip {
  &__item {
    @include primary-font-bold();
    @include font-size(13px);
    line-height: 1.2;
  }

  &__list {
    padding-left: ($space * 0.25);
    margin: math.div($space, 3) 0;

    &-item {
      @include primary-font-reg();
      @include font-size(12px);
      margin: math.div($space, 6) 0;
      display: flex;
      align-items: center;
      color: $color-dark-gray-2;

      &--valid {
        color: map-get($color-success-map, "dark");
      }
    }

    &-bullet {
      @include font-size(16px);
      margin-right: ($space * 0.25);
    }
  }
}
