$banner-height: calculateRem(48px);
$animation-time: 100ms;
$animation-timing-function: ease-in;

.notification-banner {
  position: absolute;
  right: 0;
  left: 0;
  height: 0;
  overflow: hidden;
  transition: height $animation-time $animation-timing-function;

  .alert {
    display: flex;
    align-items: center;
    transform: translateY(-$banner-height);
    transition: transform $animation-time $animation-timing-function;
  }

  &--visible {
    height: $banner-height;

    .alert {
      transform: translateY(0);
    }
  }
}
