.verification-block {
  display: flex;
  align-items: center;

  i {
    margin-right: $space;
  }

  &__text {
    font-weight: bold;
  }
}
