$sprocket-font-path: '~@showtime/sprocket/dist/fonts';
@import '~@showtime/sprocket/dist/styles/sprocket-helpers';
@import '~@showtime/sprocket/dist/styles/sprocket.css';

@import "helpers/variables";
@import "helpers/animations";
@import "helpers/flex";
@import "helpers/utils";

// Global styles, such as resets, fonts, colors, etc.
@import "base/default";
@import "base/custom";
@import "base/spaces";
@import "base/flex_grid";
@import "base/variables";
@import "base/tables";


// Styles for components
@import "components/main";
@import "components/modal-dialog";
@import "components/form";
@import "components/button";
@import "components/notification-banner";
@import "components/input-tooltip";

@import "../modules/index";

// external components styles
//@import "externals/text-editor";

// TODO: Remove me (Should be replaced by themed sprocket)
@import "shame";
