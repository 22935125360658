@keyframes spin {
  100% {  transform: rotate(359deg); }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInAndHide {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  25% {
    opacity: 1;
    transform: translateY(0);
  }

  75% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(5px);
  }
}

@keyframes spinFadeIn {
  0% {
    opacity: 0;
    transform: rotate(120deg) translateX(-20px)
  }
  100% {
    opacity: 1;
    transform: rotate(0) translateX(0)
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes hideHightlights {
  0% {
    background: $local-color-success;
    color: $color-white;
  }

  30% {
    background: $local-color-success;
    color: $color-white;
  }

  100% {
    background-color: transparent;
    color: $base-font-color;
  }
}

@keyframes hideHightlightsBlue {
  0% {
    background: $local-color-success;
    color: $color-white;
  }

  30% {
    background: $local-color-success;
    color: $color-white;
  }

  100% {
    background-color: transparent;
    color: $color-primary;
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.show-and-hide {
  animation-name: fadeInAndHide;
  animation-duration: 2.5s;
}

.spinFadeIn {
  animation-name: spinFadeIn;
  animation-duration: 0.5s;
}

.unhightlight {
  animation-name: hideHightlights;
  animation-duration: 1.5s;
}

.unhightlight-blue {
  animation-name: hideHightlightsBlue;
  animation-duration: 1.5s;
}
