.authFormWrapper {
  margin: auto;
  max-width: 380px;
  width: 100%;
  height: 100vh;
  position: relative;

  .settings-form{
    padding: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .authFormWrapper {
    height: auto;
    padding: 5px;
  }
}

.bgContainer {
  height: 100vh;
  background-image: url(/images/cinema/bg-image.png);
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 25px 6vw;
}

.bg-mobile {
  @include md-down {
    background-image: url(/images/cinema/bg-image.png);
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.hide-mobile {
  @include md-down {
    display: none!important;
  }
}

.logo-mobile {
  @include md-up {
    display: none;
  }
  @include md-down {
    margin-bottom: ($space * 2);
    text-align: center;
  }
}

.languageDropdown {
  font-size: 13px;
  position: absolute;
  left: 0;
  bottom: 30px;
  padding: 15px;
}

.dropDown {
  position: absolute;
  bottom: 100%;
  left: 0;
  padding: 10px 15px;
  border: 1px solid #C6CAD6;
  border-radius: 2px;
  background-color: #FFFFFF;
}

.droDownItem {
  line-height: 20px;
  min-width: 55px;
  cursor: pointer;
}

.height-500 {
  @include md-down {
    height: auto!important;
  }
}
