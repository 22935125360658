.theme--sport {

  /**
   * Note for sprocket/product theming development.
   * Noticing that sport theming asks to move stuff around (see paddings) ...
   * We should discourage this, and ask design to come up with as uniform layout as possible.
   * Same as Engage.
   */
  $primary-color: #5d5ded;

  .button--primary {
    background-color: $primary-color;
  }

  .checkbox-field__label:after {
    background: $primary-color;
    border-color: $primary-color;
    border: 1px solid $primary-color;
  }

  a {
    color: $primary-color;
  }

  .bg-mobile {
    @include md-down {
      background-image: url(../images/sport/bg-image.png);
    }
  }

  .bgContainer {
    background-image: url(../images/sport/bg-image.png)
  }
}
