.settings-form {
  max-width: 800px;
  padding: $space * 2;

  &__title {
    font-size: calculateRem(24px);
    margin-bottom: $space;
  }

  &__subtitle {
    font-size: calculateRem(14px);
    margin-bottom: $space;
  }

  &__title + &__subtitle {
    margin-top: -($space * 0.5);
  }

  .button {
    float: right;
  }
}

.landing-form {
  .button {
    width: 100%;

    &--lg {
      font-size: calculateRem(14px);
    }
  }
}

.form-field {
  &__label {
    font-weight: normal;
  }
}

@mixin md-down {
  @media only screen and (max-width: $md - 1) {
    @content;
  }
}

.form-input {
  &:focus {
    border-color: #3d7bd8;
  }

  &__submit {
    cursor: pointer;
    background-color: #5e9cea;
    color: #fff;
    padding: 0.75rem 2rem;
    min-width: 5.25rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-top: 1rem;
    &:hover {
      background-color: #3d7bd8;
    }
  }
}

.full-with {
  width: 100%;
}

// Removing the margin on alert icons so they don't look misaligned
.alert {
  &__icon {
    margin-top: 0;
  }
}
