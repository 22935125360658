@import "../helpers/utils";

.select-box {
  &.gray {
    background-color: $color-light-gray;
  }

  &:hover {
    background: $local-color-success-hover;
    color: $base-font-color;
  }

  &.selected {
    background: $local-color-success;
    color: $color-white;
  }
}

.select-item {
  position: relative;
  &.gray {
    background-color: $color-light-gray;
  }

  &:hover {
    background: $local-color-success-hover;
  }

  &.selected {
    background: $local-color-success;
    color: $color-white;

    .ic-check {
      display: inline-block;
    }

    &:hover {
      .ic-check {
        display: none;
      }

      .ic-close {
        display: inline-block;
      }
    }
  }

  .icon-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: rem-calc(8);
  }

  .ic {
    display: none;
    font-size: rem-calc(18);
  }

  &.disabled {
    background-color: $color-scroll-bg;
    pointer-events: none;
  }
}

// set custom arrow for dropdown
.dropdown {
  button {
    top: 0 !important;
    right: 0 !important;
    padding: 0 !important;
    height: rem-calc(34) !important;
    width: rem-calc(34) !important;
    font-size: rem-calc(10) !important;
    font-family: 'upicons' !important;
    line-height: 1;

    svg {
      display: none !important;
    }

    &:after {
      content: "\EA0A";
      display: block;
      padding: rem-calc(5);
    }
  }
}

.icon-dropdown {
  height: rem-calc(34);
  width: rem-calc(34);
  font-size: rem-calc(10);
  text-align: center;
  font-family: 'upicons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  &:after {
    content: "\e93a";
    display: inline-block;
    line-height: rem-calc(34);
  }
}

.show-underline {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}


.preview-image {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: rem-calc(100);
  height: rem-calc(135);
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.scroll-content {
  overflow: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: rem-calc(7);
    background: $color-scroll-bg;
    border-radius: rem-calc(5);
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: rem-calc(5);
    background: $color-scroll-handler;
  }

  &.thin-scroll {
    &::-webkit-scrollbar {
      width: rem-calc(4);
    }
  }
}

.flex-container {
  display: -ms-flexbox;
  display: flex;
}

.relative {
  position: relative;
}

.inline-block {
  display: inline-block;
}

.primary {
  color: $color-primary;
}

.cyan {
  color: $color-cyan;
}

.success {
  color: $local-color-success;
}

.danger {
  color: $color-danger;
}

.black {
  color: $color-black;
}

.color-warning {
  color: $color-warning;
}

.color-gray {
  color: $color-dark-gray;
}

.color-gray-light {
  color: $color-scroll-handler;
}

//Contextual backgrounds ->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.bg-grey {
  background-color: $color-dark-gray;
}

.bg-lightgrey {
  background-color: $color-light-gray;
}

.bg-cyan {
  background: $color-cyan;
  color: $color-white;
}

.bg-primary {
  background: $color-primary;
  color: $color-white;
}

.bg-success {
  background: $local-color-success;
  color: $color-white;
}

.bg-chirok {
  background: $color-chirok;
}

.bg-success-light {
  background: $local-color-success-hover;
  color: $base-font-color;
}

.bg-warning {
  background: $color-warning;
  color: $color-dark-gray;
}

.bg-white {
  background: $color-white;
}

.bg-danger {
  background: $color-danger;
  color: $color-white;
}

.bg-danger-light {
  background: $color-danger-light;
  color: $base-font-color;
}

.bg-dark {
  background-color: $color-dark;
}

.bg-black {
  background-color: $color-black;
}

.bg-table-head {
  background-color: $color-table-head;
}

// OTHERS ->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.block {
  display: block;
}

.pointer {
  cursor: pointer;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rounded {
  border-radius: $rounded;
}

.base-radius {
  border-radius: $base-radius;
}

.transition {
  transition: all 0.25s cubic-bezier(0.77, 0, 0.175, 1) 0s;
}

.uppercase {
  text-transform: uppercase;
}

.bordered {
  border: 1px solid $color-base-border;
}

.light-bordered {
  border: 1px solid $color-base-border-light;
}

.no-border {
  &, & input {
    border: none !important;
    box-shadow: none !important;
  }
}

.bb-light {
  border-bottom: 1px solid $color-base-border-light;
}

.bt-light {
  border-top: 1px solid $color-base-border-light;
}

.bl-light {
  border-left: 1px solid $color-base-border-light;
}

.br-light {
  border-right: 1px solid $color-base-border-light;
}

.bt {
  border-top: 1px solid $color-base-border;
}

.bb {
  border-bottom: 1px solid $color-base-border;
}

.br {
  border-right: 1px solid $color-base-border;
}

.bl {
  border-left: 1px solid $color-base-border;
}

.thin {
  font-weight: 300;
}

.normal {
  font-weight: 400;
}

.strong {
  font-weight: bold;
}

.capitalize {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.disabled-block {
  opacity: 0.55;
  pointer-events: none !important;
}


@media only screen and (max-width: 1200px) {
  body, html {
    font-size: 85%;
  }
}

.sort-icon {
  position: relative;
  display: inline-block;
  line-height:  rem-calc(16);
  height:  rem-calc(16);
  width:  rem-calc(16);

  &:before,
  &:after {
    border: rem-calc(4) solid transparent;
    content: "";
    display: block;
    height: 0;
    right: rem-calc(5);
    top: 50%;
    position: absolute;
    width: 0;
  }

  &.asc {
    &:before {
      border-bottom-color: #949494;
    }
  }

  &.desc {
    &:after {
      border-top-color: #949494;
    }
  }

  &:before {
    border-bottom-color: $color-black;
    margin-top: rem-calc(-9);
  }
  &:after {
    border-top-color: $color-black;
    margin-top: rem-calc(1);
  }
}

.form-label {
  display: block;
  padding: 0.5rem 0;
  font-size: $form-label-font-size;
}

.form-wrap {
  @include md-down {
    padding: $space;
    background-color: $color-white;
  }
}

// Browser Specific fixes >>>>>>>>>>>>>>>>>>>>

//Firefox
.box-shadow__none *, ::before, ::after {
  box-shadow: none;
}
