.update-button {
  display: flex;
  max-width: 475px;
  justify-content: flex-end;

  button {
    width: 130px;
    height: 35px;
  }
}
