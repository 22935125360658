/************************
Grid
The orginial grid in css:
http://flexboxgrid.com/

The grid converted in sass:
https://github.com/hugeinc/flexboxgrid-sass/blob/master/flexboxgrid.scss
************************/

.container-grid {
  max-width: $container-max-width;
  &.centered {
    margin: 0 auto;
  }
}

.negative-row {
  display: block;
  position: relative;
  margin-right: -$gutter-width * 2;
  margin-left: -$gutter-width * 2;
}

.flex {
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include flex-wrap(wrap);
}

.collapsed {
  [class^="col-"], [class*=" col-"] {
    padding-left: 0;
    padding-right: 0;
  }
}

.flex-start {
  @include justify-content(flex-start);
  text-align: start;
}

.flex-center {
  @include justify-content(center);
  text-align: center;
}

.flex-end {
  @include justify-content(flex-end);
  text-align: end;
}

.flex-top {
  @include align-items(flex-start);
}

.flex-middle {
  @include align-items(center);
}

.flex-bottom {
  @include align-items(flex-end);
}

.flex-around {
  @include justify-content(space-around);
}

.flex-between {
  @include justify-content(space-between);
}

.shrink {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
}

.flex-first {
  order: -1;
}

.flex-last {
  order: 1;
}
