
.form-field__content {
  position: relative;
}

.input-tooltip {
  position: absolute;
  background: $color-light-gray-1;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  bottom: calc(100% + 15px);
  right: -10px;
  padding: calculateRem(10px);
  width: calculateRem(240px) !important;
  visibility: hidden;
  opacity: 0;
  transition: opacity .25s ease-in-out;

  @include md-up  {
    right: -15%;
  }

  &--is-visible {
    visibility: visible; 
    opacity: 1;
  }

  &:after {
    $arrow-size: 5px;

    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 10%;
    bottom: ($arrow-size * -2);
    box-sizing: border-box;
    
    border: $arrow-size solid $color-light-gray-1;
    border-color: transparent transparent $color-light-gray-1 $color-light-gray-1;
    
    transform-origin: 0 0;
    transform: rotate(-45deg);
    
    box-shadow: -3px 2px 3px 0 rgba(0, 0, 0, 0.3);
  }
}
