@import 'Verification/index';

.login-form {
  &__password {
    &--render-icon {
      &:after {
        $size: 26px;

        position: absolute;
        font-size: $size;
        line-height: 1;
        color: $color-dark-gray-2;
        top: 50%;
        margin-top: ($size * -0.5);
        right: $space * 0.5;
      }
    }
  }

  .form-field--error {
    .text-field {
      &:after {
        visibility: hidden;
      }
    }
  }
}
