.table {
  border: rem-calc(1) solid $color-table-border;
  border-radius: rem-calc(4);
  font-size:  rem-calc(14);

  &--head {
    background-color: rgba(224, 228, 232, 0.4);
    border-bottom: rem-calc(1) solid $color-table-border;
    padding: rem-calc(10 20);
    color: $color-black;
    div, p {
      line-height: rem-calc(20);
      font-weight: 600;
    }
  }

  &--content {
    position: relative;
  }

  &--row {
    &:not(:last-of-type) {
      border-bottom: rem-calc(1) solid $color-table-border;
    }
    padding: rem-calc(10 20);
    div, p {
      line-height: rem-calc(20);
    }
  }
}