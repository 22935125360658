.app-frame {
  display: flex;
  flex-direction: row;
  padding-top: $navigation-bar-height;
  height: 100vh;
  @include sm-up {
    padding-left: $sidebar-width;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-flow: column;
    position: relative;

    &__page {
      @include container-fluid();
      display: flex;
      flex-direction: column;
      min-height: 0;
      height: 100%;
      overflow: auto;
      max-width: $container-max-width;
      margin: 0 0;
    }
  }
}

.navigation-bar--user-settings {
  background-color: #18A5B0;
}

.product-sidebar--user-settings {
  background-color: $color-slate-2;
}
