.dialog-modal {
  border-radius: 5px;
  overflow: hidden;
  & > div {
    background-color: $color-white !important;
  }

  &--title {

  }

  &--icon {
    position: absolute;
    right: rem-calc(10);
    top: rem-calc(15);
  }
}