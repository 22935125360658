@import "utils";
/************************
Colors
************************/

$color-light-gray: #F0F2F6;
$color-dark-gray: #58616C;
$color-input-border: #9FA6B3;
$color-table-border: #E0E4E8;
$color-base-border: #C3C9D2;
$color-base-border-light: #E0E4E8;
$color-dark: #3A3F49;
$color-scroll-handler: $color-base-border;
$color-scroll-bg: #E0E4E8;
$color-table-head: #f2f4f7;

$color-cyan: #00B7E3;
$local-color-success: #50D9B3;
$color-chirok: #18A5B0;
$local-color-success-hover: rgba(80, 217, 179, 0.2);

$color-danger-light: rgba(235,84,99,0.2);
$color-warning: #FCCD65;

$base-font-color: $color-dark;
$color-alert-success: #17AA85;

/************************
Font
************************/

$main-font: 'Open Sans', sans-serif;
$primary-font: $main-font;
$base-radius: 4px;
$rounded: 100px;

/************************
Breakpoints
************************/
$sm: rem-calc(768); // 768px
$md: rem-calc(1024); // 1024px
$lg: rem-calc(1200); // 1200px
$xlg: rem-calc(1500); // 1500px

/************************
Vertical BreakPoints
************************/
$v-sm: rem-calc(568); // 568px
$v-md: rem-calc(768); // 768px
$v-lg: rem-calc(900); // 900px
$v-xlg: rem-calc(1080); // 1080px

/************************
Grid
************************/
$grid-columns: 12 !default;
$gutter-width: rem-calc(10) !default;
$outer-margin: rem-calc(10) !default;

$breakpoints:
  sm 48em 46rem,
  md 64em 64rem,
  lg 75em 71rem,
  xlg 94em 91rem !default;

$container-max-width: rem-calc(1240) !default;
$gutter-compensation: $gutter-width * .5 * -1;
$half-gutter-width: rem-calc(10 * 0.5);
