@import "../helpers/utils";

html,
body,
#app,
.full-height {
  height: 100%;
}

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
}

*:focus {
  outline: none !important
}

body {
  background: $color-bg;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: $color-typo;
  font-size: 14px;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: $color-primary;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.base-font-color {
  color: $base-font-color;
}

.white {
  color: $color-white;

  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color-white;
  }

  input::-moz-placeholder { /* Firefox 19+ */
    color: $color-white;
  }

  input:-ms-input-placeholder { /* IE 10+ */
    color: $color-white;
  }

  input:-moz-placeholder { /* Firefox 18- */
    color: $color-white;
  }
}

.hidden {
  display: none;
}

h1 {
  font-size: rem-calc(32);
  font-weight: 600;
  line-height: 1.2;
}

h2 {
  font-size: rem-calc(24);
  font-weight: 600;
  line-height: 1.4;
}

h3 {
  font-size: rem-calc(18);
  font-weight: 600;
  line-height: 1.2;
}

h4 {
  font-size: rem-calc(16);
  font-weight: 600;
  line-height: 1.2;
}

h5 {
  font-size: rem-calc(14);
  font-weight: 600;
  line-height: 1.2;
}

h6 {
  font-size: rem-calc(13);
  font-weight: 600;
  line-height: 1.2;
}
